import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { rolePrivileges, rolesConfig } from '../guards/config/roles.config';
import { ISession } from '../models/session';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class RolService {
  constructor(private authService: AuthService) {}

  getUserRoles(): string {
    return this.authService.checkoutRoles();
  }
  getAllRoles(): Observable<ISession> {
    return this.authService.checkoutAccount();
  }
  canShowMenuItem(path: string): boolean {
    // Obtenemos el rol del usuario logueado
    const userRoles = this.getUserRoles();

    //Buscamos que el path entrante coincida con los configurados
    const routeConfig = rolesConfig.routes.find((route) => route.path === path);

    if (!routeConfig) {
      // La ruta no existe, ocultarla
      return false;
    }
    // Verificamos si el usuario tiene su rol en la lista de roles permitidos en para la ruta
    return routeConfig.roles.some((role) => role === userRoles);
  }

  enablePrivileges(path: string): boolean {
    // Obtenemos el rol del usuario logueado
    const userRoles = this.getUserRoles();

    //Buscamos que el path entrante coincida con los configurados
    const routePrivileges = rolePrivileges.routes.find((route) => route.path === path);

    if (!routePrivileges) {
      // La ruta no existe, ocultarla
      return false;
    }
    // Verificamos si el usuario tiene su rol en la lista de roles permitidos en para la ruta
    return routePrivileges.roles.some((role) => role === userRoles);
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import {
  MsalModule,
  MsalRedirectComponent,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
} from '@azure/msal-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BcMenuModule } from '@bancolombia/design-system-web/bc-menu';
import {BcHeaderModule} from '@bancolombia/design-system-web/bc-header';
import {BcFloatMenuModule} from '@bancolombia/design-system-web/bc-float-menu'
import {BcButtonModule} from '@bancolombia/design-system-web/bc-button';
import {BcIllustrationModule} from '@bancolombia/design-system-web/bc-illustration'

import { BrowserCacheLocation, IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { AlertInterceptorService } from './core/services/alert-interceptor.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import {BcPictogramModule} from '@bancolombia/design-system-web/bc-pictogram';
import { HomeComponent } from './home/home.component'

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSAL_InterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.mctBaseUrl, [
    environment.azureConfig.scope,
  ]);
  protectedResourceMap.set('https://run.mocky.io/v3', [
    environment.azureConfig.scope,
  ]);
  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureConfig.clientId, // Application (client) ID from the app registration
      authority: `${environment.azureConfig.loginMicrosoft}/${environment.azureConfig.tenantId}`,
      // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
      redirectUri: environment.azureConfig.redirectUri, // This is your redirect URI
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}
@NgModule({
  
  declarations: [
    AppComponent,
    MenuHeaderComponent,
    UnauthorizedComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,

    BcMenuModule,
    BcHeaderModule,
    BcFloatMenuModule,
    BcButtonModule,
    BcAlertModule,
    BcIconModule.forRoot(
      { path: 'https://library-sdb.apps.bancolombia.com/bds/7.7.10' 
    }),
    BcIllustrationModule
    .forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/7.7.10' }),

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlertInterceptorService,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSAL_InterceptorConfigFactory,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    HttpClient,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { filter, firstValueFrom } from 'rxjs';

export const roleGuard = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
  const authService = inject(MsalService);
  const msalBroadcastService = inject(MsalBroadcastService);
  const router = inject(Router);

  await authService.instance.initialize();

  // Espera a que no haya interacciones en progreso
  const interactionStatus = await firstValueFrom(
    msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
  );

  const requiredRoles: string[] = route.data['roles'];
  const account: AccountInfo | null = authService.instance.getActiveAccount();
  

  if (!account) {
    authService.loginRedirect();
    return false;
  }
  if (!requiredRoles || requiredRoles.length === 0) {
    return true;
  }
  const roles: string[] = account.idTokenClaims?.['roles'] || [];
  const hasRequiredRole = requiredRoles.some((role) => roles.includes(role));

  if (!hasRequiredRole) {
    router.navigate(['/unauthorized']);
    return false;
  }

  return true;
};
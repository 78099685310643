import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BcIconModule } from '@bancolombia/design-system-web/bc-icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BcMenuModule } from '@bancolombia/design-system-web/bc-menu';
import {BcHeaderModule} from '@bancolombia/design-system-web/bc-header';
import {BcFloatMenuModule} from '@bancolombia/design-system-web/bc-float-menu'
import {BcButtonModule} from '@bancolombia/design-system-web/bc-button';
import {BcIllustrationModule} from '@bancolombia/design-system-web/bc-illustration'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { AlertInterceptorService } from './core/services/alert-interceptor.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { HomeComponent } from './home/home.component'
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { AuthInterceptorService } from './core/interceptor/auth-interceptor.service';
import { environment } from '@env/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureConfig.clientId,
      authority: environment.azureConfig.loginMicrosoft + '/' + environment.azureConfig.tenantId,
      redirectUri: environment.azureConfig.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

@NgModule({
  
  declarations: [
    AppComponent,
    MenuHeaderComponent,
    UnauthorizedComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BcMenuModule,
    BcHeaderModule,
    BcFloatMenuModule,
    BcButtonModule,
    BcAlertModule,
    BcIconModule.forRoot(
      { path: 'https://library-sdb.apps.bancolombia.com/bds/7.14.5' 
    }),
    BcIllustrationModule
    .forRoot({ path: 'https://library-sdb.apps.bancolombia.com/bds/7.14.5' }),

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlertInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    HttpClient
  ],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }

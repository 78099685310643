import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@env/environment';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor(private msalService: MsalService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount) {
      return next.handle(req);
    }
    return this.msalService.acquireTokenSilent({
      account: activeAccount,
      scopes: [environment.azureConfig.scope],
    }).pipe(
      switchMap((result) => {
        const authReq = req.clone({
          setHeaders: {
            Authorization: `Bearer ${result.accessToken}`,
          },
        });
        return next.handle(authReq);
      })
    );
  }
}

export const CLIENTES_ROUTE = 'clientes/';
export const CREAR_CLIENTE_MX_ROUTE = 'crearClienteMx';
export const CLIENTES_BLOQUEADOS_ROUTE = 'clientesBloqueados';
export const CLIENTES_INCUMPLIDOS_ROUTE = 'clientesIncumplidos';
export const ASIGNAR_TRADER = 'asignar-trader';

export const TRADER_ROUTE = 'trader/';
export const PARAMETRIZAR_TRADER = 'parametrizar_trader';
export const MESAS = 'mesas';
export const PARAMETROS_RELACION = 'parametros-relacion';
export const SEGMENTO_MESA = 'segmento-mesa';

export const SEGMENTOS_ROUTE = 'segmentos/';
export const LISTA_SEGMENTOS_ROUTE = 'lista-segmentos';


export const HOMOLOGACIONES_ROUTE = 'homologaciones/';
export const EQUIVALENCIAS_ROUTE = 'equivalencias';
export const DESCRIPCION_ENTIDADES_ROUTE = 'descentidades';
export const EXCLUIDOS_ROUTE = 'excluidos';



import { CLIENTES_BLOQUEADOS_ROUTE, CLIENTES_INCUMPLIDOS_ROUTE, CLIENTES_ROUTE, CREAR_CLIENTE_MX_ROUTE } from "../../constants/routes";

export const rolesConfig = {
    routes: [
      {
        path: CREAR_CLIENTE_MX_ROUTE,
        parentPath: CLIENTES_ROUTE,
        parentName: 'Clientes',
        name: 'Replicar cliente Murex',
        roles: [
          'MPT_SVN_CONSUL',
          'MPT_SVN_JEFE',
          'MPT_SVN_PARAM',
          'MPT_SVN_TRADER',
          'MPT_SVN_TRADERPARAM',
          'MPT_SVN_TI',
          'MCT_ADMIN'
        ],
      },
      /* {
        path: CLIENTES_BLOQUEADOS_ROUTE,
        parentPath: CLIENTES_ROUTE,
        parentName: 'Clientes',
        name: 'Clientes bloqueados',
        roles: [
          'MPT_SVN_CONSUL',
          'MPT_SVN_JEFE',
          'MPT_SVN_PARAM',
          'MPT_SVN_TRADER',
          'MPT_SVN_TRADERPARAM',
          'MPT_SVN_TI',            
          'MCT_ADMIN'
        ],
      }, */
      {
        path: CLIENTES_INCUMPLIDOS_ROUTE,
        parentPath: CLIENTES_ROUTE,
        parentName: 'Clientes',
        name: 'Clientes incumplidos',
        roles: [
          'MPT_SVN_CONSUL',
          'MPT_SVN_JEFE',
          'MPT_SVN_PARAM',
          'MPT_SVN_TRADER',
          'MPT_SVN_TRADERPARAM',
          'MPT_SVN_TI',            
          'MCT_ADMIN'
        ],
      },
      /*
      {
        path: 'survey',
        roles: [
          'GERENTECOMERCIAL',
          'ASESORCOMERCIAL',
          'SUPERUSER',
          'TESORERIA',
          'VINCULACION'
        ],
      },
      {
        path: 'convenienceAnalysisComponent',
        roles: ['GERENTEREGIONAL', 'GERENTECOMERCIAL', 'SUPERUSER'],
      },
      {
        path: 'recommendationHistoryComponent',
        roles: [
          'GERENTEREGIONAL',
          'GERENTECOMERCIAL',
          'ASESORCOMERCIAL',
          'SUPERUSER',
          'MONITOREO',
        ],
      },
      {
        path: 'newsHistoryComponent',
        roles: [
          'GERENTEREGIONAL',
          'GERENTECOMERCIAL',
          'ASESORCOMERCIAL',
          'SUPERUSER',
          'RIESGOMERCADO',
          'MONITOREO',
        ],
      },
      {
        path: 'parameterizations',
        roles: ['SUPERUSER', 'PARAMETRIZACION'],
      }, */
    ],
  };

export const rolePrivileges = {
  routes: [
    {
      path: CREAR_CLIENTE_MX_ROUTE,
      roles: [
        'MPT_SVN_PARAM',
        'MPT_SVN_TRADER',
        'MPT_SVN_TRADERPARAM',
        'MCT_ADMIN'
      ],
    },
    {
      path: CLIENTES_BLOQUEADOS_ROUTE,
      roles: [
        'MPT_SVN_PARAM',
        'MPT_SVN_TRADER',
        'MPT_SVN_TRADERPARAM',
        'MCT_ADMIN'
      ],
    },
  ],
};

import { Component, Inject, OnInit } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { filter, Subject, takeUntil } from 'rxjs';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { rolesConfig } from '@app/core/guards/config/roles.config';
import { Router } from '@angular/router';
interface RouteConfig {
  parentPath: string;
  parentName: string;
  icon: string;
  children: ChildRoute[];
}

interface ChildRoute {
  path: string;
  name: string;
  roles?: string[];
}

interface MenuSection {
  path: string;
  icon: string;
  name: string;
  children: MenuItem[];
}

interface MenuItem {
  path: string;
  name: string;
}
@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.css'],
})
export class MenuHeaderComponent implements OnInit {
  loginDisplay = false;
  menuReady = true;
  name = '';
  tokenExpiration: string = '';
  public menu: any[] = [];
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) {}

  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe((msg) => {
        this.tokenExpiration = (msg.payload as any).expiresOn;
        localStorage.setItem('tokenExpiration', this.tokenExpiration);
      });
    this.buildMenuFromRoles();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.authService.instance.setActiveAccount(
      this.authService.instance.getAllAccounts().shift()!
    );
    this.name = this.authService.instance.getActiveAccount()?.name || '';
    this.buildMenuFromRoles();
  }
  buildMenuFromRoles() {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      return;
    }
    const userRoles: string[] = account.idTokenClaims?.['roles'] || [];
    this.menu = rolesConfig.routes
      .map((routeConfig) => this.buildMenuSection(routeConfig, userRoles))
      .filter((section) => section.children.length > 0);

    this.menuReady = false;
    setTimeout(() => {
      this.menuReady = true;
    }, 0);
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  navigateToUrl(url: string) {
    this.router.navigate([url]);
  }

  private buildMenuSection(
    routeConfig: RouteConfig,
    userRoles: string[]
  ): MenuSection {
    const children = routeConfig.children
      .filter((child) => this.hasAccess(child, userRoles))
      .map((child) => ({
        path: routeConfig.parentPath + child.path,
        name: child.name,
      }));

    return {
      path: routeConfig.parentPath,
      icon: routeConfig.icon,
      name: routeConfig.parentName,
      children,
    };
  }
  private hasAccess(route: ChildRoute, userRoles: string[]): boolean {
    return !route.roles || route.roles.some((role) => userRoles.includes(role));
  }
}
